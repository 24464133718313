import usePrevious from "./usePrevious";

const useChildrenLoaded = (ref) => {
  const currentText = ref?.current?.innerText || ref?.innerText
  const currentChildrenCount = ref?.current?.childElementCount || ref?.childElementCount

  const priorText = usePrevious(currentText);
  const priorChildrenCount = usePrevious(currentChildrenCount);

  const textChanged = priorText !== currentText
  const childrenChanged = currentChildrenCount !== priorChildrenCount

  return (
    textChanged || childrenChanged
  );
};

export default useChildrenLoaded;
