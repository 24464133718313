import { useCallback, useState } from 'react'

const usePageHeading = () => {
    const [heading, setHeading] = useState('')
    const [options, setOptions] = useState({})
    
    const setCurrentHeading = useCallback((newHeading = '') => {
        setHeading(current => {
            if(current !== newHeading) {
                return newHeading
            }
        })
    }, [])

    const setCurrentOptions = useCallback((newOptions = {}) => {
        setOptions(current => {
            if(current !== newOptions) {
                return newOptions
            }
        })
    }, [])

    return {
        heading,
        options,
        setHeading: setCurrentHeading,
        setOptions: setCurrentOptions
    }
}

export default usePageHeading