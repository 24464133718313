export const scrollToPosition = (offsetTop, top) => {
  if (offsetTop) {
    window.scrollTo({
      top: offsetTop - top,
      left: 0,
      behavior: "smooth",
    });
  }
};

export function scrollToHash(container = document, hash = "") {
  const formattedHash = hash.startsWith("#") ? hash.substring(1) : hash;
  const hashElement = formattedHash && container.getElementById(formattedHash);

  if (hashElement) {
    hashElement.scrollIntoView();
    hashElement.focus();
  }

  return hashElement;
}

export const elementIsInView = (element, top = 0, rounding = 20) => {
  let percentage = {
    ofSelf: 0,
    ofScreen: 0,
  };
  if (!element) {
    return percentage
  }
  const clientRectangle = element.getBoundingClientRect();
  const elementHeight = element.offsetHeight;
  const elementWidth = element.offsetWidth;
  const windowWidth = window.innerWidth || document.documentElement.clientWidth;
  const windowHeight =
    window.innerHeight || document.documentElement.clientHeight;

  const leftWithinThreshold = clientRectangle.left >= -elementWidth;
  const topWithinThreshold = clientRectangle.top >= -elementHeight + top;
  const rightWithinThreshold =
    clientRectangle.right <= windowWidth + elementWidth;
  const bottomWithinThreshold =
    clientRectangle.bottom <= windowHeight + elementHeight;

  let visibleHeight = element.scrollHeight;
  const aboveTopValue = clientRectangle.top - top;
  if (aboveTopValue < top) {
    visibleHeight += aboveTopValue;
  }
  if (clientRectangle.bottom > windowHeight) {
    visibleHeight -= clientRectangle.bottom - windowHeight;
  }

  const visible =
    leftWithinThreshold &&
    topWithinThreshold &&
    rightWithinThreshold &&
    bottomWithinThreshold;
  if (visible) {
    percentage.ofSelf = visibleHeight / element.scrollHeight;
    percentage.ofScreen = visibleHeight / windowHeight;
  }

  if(rounding) {
    const bufferByRounding = (value) => Math.round(value * rounding) / rounding

    percentage.ofSelf = bufferByRounding(percentage.ofSelf)
    percentage.ofScreen = bufferByRounding(percentage.ofScreen)
  }

  return percentage;
};
