import React from 'react'
import usePageHeading from './usePageHeading'
import { PAGE_HEADING_DEFAULT_VALUE, PageHeadingProvider } from './context'

const withPageHeading = (WrappedComponent) => {
  function _withPageHeading(props = {}) {
    const componentName = WrappedComponent.displayName || WrappedComponent.name || "Component";
    const { heading, options = {}, setHeading, setOptions } = usePageHeading();
    
    _withPageHeading.displayName = `withPageHeading(${componentName})`;

    const context = Object.assign({}, PAGE_HEADING_DEFAULT_VALUE, {
      heading, options, setHeading, setOptions
    })

    return (
      <PageHeadingProvider value={context}>
        <WrappedComponent {...props} />
      </PageHeadingProvider>
    );
  }
  return _withPageHeading;
};

export default withPageHeading;
