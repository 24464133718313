import { createContext } from 'react';

export const TOC_DEFAULT_VALUE = {
    paths: [],
    exists: false,
}
export const TableOfContentsContext = createContext(TOC_DEFAULT_VALUE);
export const TableOfContentsProvider = TableOfContentsContext.Provider;

export const TOC_POSITIONING_DEFAULT_VALUE = {}
export const TableOfContentsPositioningContext = createContext(TOC_POSITIONING_DEFAULT_VALUE);
export const TableOfContentsPositioningProvider = TableOfContentsPositioningContext.Provider;

export const TOC_CONTROLLER_DEFAULT_VALUE = {
    navHeight: 0,
    setPaths: () => {},
    dispatchPosition: () => {},
}
export const TableOfContentsControllerContext = createContext(TOC_CONTROLLER_DEFAULT_VALUE);
export const TableOfContentsControllerProvider = TableOfContentsControllerContext.Provider;