import {
  useLayoutEffect,
  useCallback,
  useState,
  useRef,
  useEffect,
} from "react";
import { elementIsInView } from "../utilities/domMutations";
import useChildrenLoaded from "./useChildrenLoaded";

const usePercentVisible = (ref, options = {}) => {
  let element = ref instanceof Element ? ref : ref?.current
  const { navHeight, rounding = 20 } = options;
  const [visibility, setVisibility] = useState(
    elementIsInView(element, navHeight, rounding)
  );
  const childrenLoaded = useChildrenLoaded(element)
  const scrollTimeout = useRef();

  const handleScroll = useCallback(() => {
    if (!element) {
      return null;
    }

    clearTimeout(scrollTimeout.current);

    scrollTimeout.current = setTimeout(() => {
      const elementIsOnScreen = elementIsInView(element, navHeight, rounding);
      setVisibility(elementIsOnScreen);
    }, 50);
  }, [element, navHeight]);

  useLayoutEffect(() => {
    if (childrenLoaded) {
      handleScroll();
    }
  }, [handleScroll, childrenLoaded]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, {
      capture: false,
      passive: true,
    });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return {
    ...visibility,
  };
};

export default usePercentVisible;
