import { createContext } from 'react';

export const PAGE_HEADING_DEFAULT_VALUE = {
    heading: '',
    options: {
        setHeading: () => {}, 
        setOptions: () => {}
    }
}

export const PageHeadingContext = createContext(PAGE_HEADING_DEFAULT_VALUE);
export const PageHeadingProvider = PageHeadingContext.Provider;