import React, {
  useContext,
  useEffect,
  useRef
} from "react";
import PropTypes from 'prop-types';
import usePercentVisible from "../../hooks/usePercentVisible";
import { TableOfContentsControllerContext } from "../context";
import AnchorHeading from "./AnchorHeading";

/**
 * Section is used to define a section of a page, with its first child being an h2 containing a heading. The id of the section will be the props.id, 
 * and the heading component is the props.id with a suffix of `-heading`. i.e. Output of `<section id="section" />` and  `<h2 id="section-heading" />`.
 * 
 * This will use the table of contents context to update the table of contents, but does allow manual overrides for nav height, paths, and setPaths.
 * 
 * If further customization is required for the heading, AnchorHeadingProps is an object sent to the heading for any additional customizations, 
 * including variant, tabIndex, navHeight, and className. This also accepts any Typography props from Material-UI: https://material-ui.com/api/typography/
 */

function Section(props = {}) {
  const { id, sortPriority, setPaths: overrideSetPaths, heading, children, AnchorHeadingProps, loading, ...rest } = props;
  const { navHeight, dispatchPosition, setPaths: contextSetPaths } = useContext(TableOfContentsControllerContext)
  const setPaths = overrideSetPaths || contextSetPaths

  const ref = useRef();
  const headingId = `${id}-heading`;
  const { ofScreen, ofSelf } = usePercentVisible(ref, { navHeight });

  useEffect(() => {
    dispatchPosition({
      id: headingId,
      ofScreen,
      ofSelf
    })
  }, [ofScreen, ofSelf, dispatchPosition])

  useEffect(() => {
    if (!ref || !ref.current) {
      return null;
    }
    const section = {
      id: headingId,
      label: heading,
      loading,
      sortPriority
    };

    setPaths({
      type: 'append',
      section
    })
  }, [setPaths, headingId, heading, loading, sortPriority]);

  return (
    <section id={id} ref={ref} {...rest}>
      {heading && <AnchorHeading navHeight={navHeight} id={headingId} {...AnchorHeadingProps}>{heading}</AnchorHeading>}
      {children}
    </section>
  );
}

Section.defaultProps = {
  loading: false,
  AnchorHeadingProps: {}
};

Section.propTypes = {
  loading: PropTypes.bool,
  id: PropTypes.string.isRequired,
  sortPriority: PropTypes.number,
  navHeight: PropTypes.number,
  heading: PropTypes.string,
  AnchorHeadingProps: PropTypes.object,
  children: PropTypes.node,
  setPaths: PropTypes.func
}

export default Section;
