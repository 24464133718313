import PropTypes from "prop-types";

export const color = ["primary", "secondary"];
export const tocPathShape = {
  id: PropTypes.string,
  label: PropTypes.string,
  loading: PropTypes.bool,
  percentVisible: PropTypes.shape({
    ofSelf: PropTypes.number,
    ofScreen: PropTypes.number,
  }),
};
