import React, { Fragment, useContext } from "react";
import PropTypes from 'prop-types';
import { TableOfContentsContext } from "../context";
import Popover from "./Popover";
import PopoverButton from "./PopoverButton";
import Sidebar from "./Sidebar";
import { tocPathShape } from "../../utilities/prop-types";
import { sortBySortPriority } from "../../utilities/sort";

const TableOfContents = (props = {}) => {
  const {
    variant,
    PopoverButtonProps,
    PopoverProps,
    paths: overridePaths,
    width: propWidth,
    hideOnEmpty,
    ...rest
  } = props;
  const { paths = [], width: contextWidth } = useContext(TableOfContentsContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const popoverId = open ? "toc-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  let tocPaths = [];
  if (Array.isArray(overridePaths) && overridePaths.length > 0) {
    tocPaths = overridePaths;
  } else if (paths.length > 0) {
    tocPaths = paths;
  }

  if(tocPaths.length < 1 && hideOnEmpty) {
    return null
  }

  tocPaths = sortBySortPriority(tocPaths)

  if (variant === 'popover') {
    return (
      <Fragment>
        <PopoverButton {...PopoverButtonProps} onClick={handleClick} />
        <Popover
          {...PopoverProps}
          BaseLinkProps={{
            ...(PopoverProps.BaseLinkProps || {}),
            onClick: handleClick,
          }}
          id={popoverId}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          onClick={handleClose}
          paths={tocPaths}
        />
      </Fragment>
    );
  }

  return <Sidebar paths={tocPaths} width={contextWidth || propWidth} {...rest} />;
};

TableOfContents.defaultProps = {
  PopoverButtonProps: {},
  PopoverProps: {},
  variant: 'sidebar',
  width: '15rem',
  hideOnEmpty: true
};

TableOfContents.propTypes = {
  PopoverButtonProps: PropTypes.object,
  PopoverProps: PropTypes.shape({
      BaseLinkProps: PropTypes.object
  }),
  hideOnEmpty: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variant: PropTypes.oneOf(['popover', 'sidebar']),
  paths: PropTypes.arrayOf(
    PropTypes.shape(tocPathShape)
  )
};

export default TableOfContents;
