import React from "react";
import PropTypes from "prop-types";
import {
  Typography
} from "../../libraries/material/core";

const _Heading = (props = {}) => {
  const { level, ...rest } = props;
  const headingComponent = `h${level}`;

  return <Typography variant={headingComponent} {...rest} />;
};

_Heading.propTypes = {
    level: PropTypes.oneOf([1, 2, 3, 4, 5, 6, '1', '2', '3', '4', '5', '6'])
}

export default _Heading;
