import { useEffect, useReducer } from 'react'
import { useLocation } from 'react-router-dom'

function positionReducer(state = {}, action = {}) {
  let newState = Object.assign({}, state)
  if(action?.id) {
      const { ofSelf = 0, ofScreen = 0 } = action
      newState[action.id] = {
          ofSelf,
          ofScreen
      }
  }

  return newState
}

function pathsReducer(state = [], action) {
    let paths = Array.isArray(state) ? state : []
    if(Array.isArray(action)) {
        return action
    }

    if(action?.type === 'append' && action?.section?.id) {
        let filteredPaths = paths.filter((path) => path.id !== action.section.id);
        filteredPaths.push(action.section);

        return filteredPaths
    } 

    return paths
}

const useTableOfContents = (options = {}) => {
    const { navHeight } = options
    const { pathname } = useLocation()
    const [position, dispatchPosition] = useReducer(positionReducer, {});
    const [paths, setPaths] = useReducer(pathsReducer, []);

    useEffect(() => {        
        return () => {
            setPaths([])
        }
    }, [pathname])

    return {
        navHeight,
        paths,
        exists: Array.isArray(paths) && paths.length > 0,
        position,
        dispatchPosition,
        setPaths
    }
}

export default useTableOfContents