import React from "react";
import OSUThemeProvider from "./OSUThemeProvider";

function withOSUTheming(Component) {
    return function _withOSUTheming (props = {}) {
        const { children, theme = null, darkMode, embedFonts = true, ...rest } = props
        const componentName = Component.displayName || Component.name || "Component";

        _withOSUTheming.displayName = `withOSUTheming(${componentName})`;

        let componentProps = rest
        if(darkMode !== undefined) {
          componentProps.darkMode = darkMode
        }

        return (
          <OSUThemeProvider embedFonts={embedFonts} theme={theme} darkMode={darkMode}>
            <Component {...componentProps}>
                {children}
            </Component>
          </OSUThemeProvider>
        );
    }
}

export default withOSUTheming